import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Seo from 'components/seo';

import { SitemapItemData } from 'apiFolder/directus-dynamic';

import Header from 'components/common/header/Header';
import ThemeProviderComponent from 'components/common/ThemeProvider';
import PaddingTopContainer from 'components/common/header/PaddingTopContainer';

interface ISiteMapTemplate {
	pageContext: {
		item: SitemapItemData;
	};
}

const SiteMapTemplate: React.FC<ISiteMapTemplate> = ({ pageContext }) => {
	const [isMenuOpen, toggleMenu] = useState(false);
	const { h1, metaTitle, pageSections } = pageContext.item;

	const toggleBurgerMenu = () => {
		toggleMenu(!isMenuOpen);
	};

	return (
		<>
			<Seo title={metaTitle} />
			<ThemeProviderComponent>
				<Header toggleBurgerMenu={toggleBurgerMenu} isBurgerOpen={isMenuOpen} />
				<PaddingTopContainer isBurgerOpen={isMenuOpen}>
					<TitleBlock>
						<Wrap>
							<Title>{h1}</Title>
						</Wrap>
					</TitleBlock>
					<LinksWrap>
						{pageSections.map((section) => (
							<Section key={section.h2}>
								<Label>{section.h2}</Label>
								<LinksBlock>
									{section.sectionLinks.map((link) =>
										link.link ? (
											<Link key={link.link} to={link.link}>
												{link.linkText}
											</Link>
										) : (
											<PlainLink key={link.linkText}>{link.linkText}</PlainLink>
										),
									)}
								</LinksBlock>
							</Section>
						))}
					</LinksWrap>
				</PaddingTopContainer>
			</ThemeProviderComponent>
		</>
	);
};
const TitleBlock = styled.div`
	display: relative;
	left: 0;
	width: 100%;
	background-color: #424bec;
	padding: 40px 0;

	@media (max-width: 767px) {
		padding: 20px 0;
	}
`;
const Wrap = styled.div`
	max-width: 820px;
	width: 100%;
	margin: 0 auto;
	padding: 20px 15px;
`;
const LinksWrap = styled(Wrap)`
	background-color: #ffffff;
	padding: 20px 15px 50px;
`;
const Section = styled.section`
	width: 100%;
`;
const Label = styled.h2`
	font-family: 'SharpGrotesk-MediumNo21', sans-serif;
	padding: 40px 0 20px;
	font-weight: 600;
	font-size: 18px;
	line-height: 1.5;
	width: 100%;

	@media (max-width: 767px) {
		padding: 40px 0 20px;
	}
`;
const Title = styled.h1`
	font-family: 'SharpGrotesk-MediumNo21', sans-serif;
	font-weight: 400;
	line-height: 1.3;
	color: #ffffff;
	font-size: 30px;

	@media (max-width: 1024px) {
		font-size: 24px;
	}
`;
const LinksBlock = styled.ul`
	display: flex;
	flex-wrap: wrap;
	& a {
		flex: 0 0 30%;
		max-width: 30%;
		min-width: 30%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		margin: 0 20px 15px 0;
		color: ${({ theme }) => theme.palette.purp};
		transition: all ease 0.3s;

		@media (min-width: 1367px) {
			&:hover {
				color: ${({ theme }) => theme.palette.grey_text};
				text-decoration: none;
			}
		}
		@media (max-width: 767px) {
			flex: 0 0 calc(49% - 25px);
			max-width: calc(49% - 25px);
			min-width: calc(49% - 25px);
			margin: 0 10px 20px 0;
		}
		@media (max-width: 376px) {
			flex: 0 0 calc(100% - 30px);
			max-width: calc(100% - 30px);
			min-width: calc(100% - 30px);
		}
	}
`;
const PlainLink = styled.div`
	flex: 0 0 30%;
	max-width: 30%;
	min-width: 30%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin: 0 20px 15px 0;
	color: ${({ theme }) => theme.palette.purp};
`;
export default SiteMapTemplate;
